<template>
  <div class="org">
    <!--海报-->
    <div class="flex-box">
      <div class="banner" @click="scrollTo('intro')">
        <img v-lazy="require('@/assets/interHospital/Frame 4256.jpg')" class="banner-img" />
      </div>
    </div>
    <!--区域导航-->
    <div class="tabs" ref="tabs">
      <div class="contain">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          @click="scrollTo(tab.id)"
          class="tab"
        >
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--悬浮的区域导航-->
    <div class="tabs fixed" v-if="showTabHeader">
      <div class="contain">
        <div
          v-for="tab in tabs"
          :key="tab.id"
          @click="scrollTo(tab.id)"
          :class="['tab', tab.hover ? 'hover' : '']"
        >
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--方案介绍-->
    <div class="intro" ref="intro">
      <div class="title-box reveal">
        <div class="title1">针对不同人群，提供专业课程</div>
      </div>
      <div class="intro-contain">
        <div class="box reveal1">
          <img
            v-lazy="require('@/assets/interHospital/Group 1.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <div class="box-title">零基础心理学</div>
            </div>
            <div class="box-intro">
              入门级考证、考证
            </div>
            <div class="box-intro">
              <button class="btn btn-login">预约试听</button>
            </div>
          </div>
        </div>
        <div class="box reveal2">
          <img
            v-lazy="require('@/assets/interHospital/Group 2.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <div class="box-title">心理咨询师职业技能与实践</div>
            </div>
            <div class="box-intro">
              执业必修
            </div>
            <div class="box-intro">
              <button class="btn btn-login">预约试听</button>
            </div>
          </div>
        </div>
        <div class="box reveal3">
          <img
            v-lazy="require('@/assets/interHospital/Group 3.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <div class="box-title">精神分析连续培训与督导</div>
            </div>
            <div class="box-intro">
              成长选修
            </div>
            <div class="box-intro">
              <button class="btn btn-login">预约试听</button>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div style="margin-top:20px;" class="intro-contain">
        <div class="box reveal1">
          <img
            v-lazy="require('@/assets/interHospital/Group 4.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <div class="box-title">心理学在职研修课程</div>
            </div>
            <div class="box-intro">
              IN-SERVICE TRAINING CLASS
            </div>
            <div class="box-intro">
              <button class="btn btn-login">预约试听</button>
            </div>
          </div>
        </div>
        <div class="box reveal2">
          <img
            v-lazy="require('@/assets/interHospital/Group 5.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <div class="box-title">应用心理学高级研修班</div>
            </div>
            <div class="box-intro">
              SENIOR SEMINAR
            </div>
            <div class="box-intro">
              <button class="btn btn-login">预约试听</button>
            </div>
          </div>
        </div>
        <div class="box reveal3">
          <img
            v-lazy="require('@/assets/interHospital/Group 6.png')"
            class="box-img"
          />
          <div class="box-main">
            <div class="flex">
              <div class="box-title">心理学公开课、研讨沙龙</div>
            </div>
            <div class="box-intro">
              OPEN CLASS
            </div>
            <div class="box-intro">
              <button class="btn btn-login">预约试听</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--适用场景-->
    <div class="scene" ref="scene">
      <div class="title-box reveal">
        <div class="title1">咨询师的成长之路</div>
        <div class="title2">
          专业心理咨询师养成计划
        </div>
      </div>
      <div class="scene-contain">
        <div class="bg-img">
          <div class="card1">
            <div class="card-one">
              <p class="title">基础入门</p>
              <p class="msg">中科院心理咨询师基</p>
              <p class="msg">础培训课程</p>
              <div class="h1"></div>
              <div class="small">
                <div class="small-box">
                  <span class="small-dian"></span><span class="">基础知识</span>
                </div>
                <div class="small-box">
                  <span class="small-dian"></span><span class="">基础技能</span>
                </div>
                <div class="small-box">
                  <span class="small-dian"></span><span class="">合格证书</span>
                </div>
                
              </div>
              <div class="msg-yellow">
                <p class="yellow-p">164学时基础培训课程+40学时线上兴趣拓展课</p>
                <p class="yellow-p">程包，心理咨询师入门必备知识储备</p>
              </div>
              <div class="card-img">
                <img v-lazy="require('@/assets/interHospital/Group 495.png')" src="" alt="">
              </div>
            </div>
            <div class="card-one">
              <p style="margin-left:420px;" class="title">流派技能</p>
              <p style="margin-left:254px;" class="msg">熟练掌握多种流派理论</p>
              <p style="margin-left:478px;" class="msg">技术</p>
              <div style="margin-left:382px;" class="h1"></div>
              <div style="margin-left:100px;" class="small">
                <div class="small-box">
                  <span class="small-dian"></span><span class="">A阶段：三大主流技术+后现代<br> 流派学习72学时</span>
                </div>
                <div class="small-box">
                  <span class="small-dian"></span><span class="">B阶段：咨询进阶理论知识<br> 48学时</span>
                </div>
              </div>
              <div style="margin-left:240px;" class="msg-yellow">
                <p class="yellow-p">从基础理论到实践操作跨越的第一步</p>
                <!-- <p class="yellow-p">程包，心理咨询师入门必备知识储备</p> -->
              </div>
              <div class="card-img2">
                <img v-lazy="require('@/assets/interHospital/Group 496.png')" src="" alt="">
              </div>
            </div>
          </div>
          <div class="card1">
            <div class="card-one">
              <p class="title">督导实习</p>
              <p class="msg">加快咨询实战上手进程</p>
              <div class="h1"></div>
              <div style="margin-top:20px;">
                <div style="margin-left:40px;margin-top:10px;">
                  <span class="">30次咨询实践+个人完整案例报告</span>
                </div>
                <div style="margin-left:40px;margin-top:10px;">
                  <span class="">10次一对一个案督导</span>
                </div>
                <div style="margin-left:40px;margin-top:10px;">
                  <span class="">优秀学员获得咨询中心签约机会</span>
                </div>
              </div>
              <div class="msg-yellow">
                <p class="yellow-p">走上职业咨询师之路的重要保障</p>
              </div>
              <div class="card-img3">
                <img v-lazy="require('@/assets/interHospital/Ellipse 23.png')" src="" alt="">
              </div>
            </div>
            <div class="card-one">
              <p style="margin-left:420px;" class="title">观摩研讨</p>
              <p style="margin-left:310px;" class="msg">完整个案现场观摩</p>
              <div style="margin-left:384px;" class="h1"></div>
              <div style="margin-top:10px;">
                <div style="margin-left:300px;margin-top:10px;">
                  <span class="">【认知行为疗法】10次观摩+研讨</span>
                </div>
                <div style="margin-left:300px;margin-top:10px;">
                  <span class="">【精神分析疗法】20次观摩+研讨</span>
                </div>
                <div style="margin-left:300px;margin-top:10px;">
                  <span class="">【家庭治疗】8次观摩+研讨</span>
                </div>
              </div>
              <div style="margin-left:210px;" class="msg-yellow">
                <p class="yellow-p">真实个案观摩及研讨，资深心理咨询师现</p>
                <p class="yellow-p">场讲解</p>
              </div>
              <div class="card-img4">
                <img v-lazy="require('@/assets/interHospital/Group 497.png')" src="" alt="">
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <!--专业性说明-->
    <div class="tool" ref="tool">
      <div style="padding-bottom:40px;"  class="title-box reveal">
        <div class="title1">走入康美心理，你便拥有了</div>
      </div>
      <div class="imgs-box">
        <div class="imgs_box-flex">
          <div class="aimg">
            <img v-lazy="require('@/assets/interHospital/1.png')" src="" alt="">
          </div>
          <div class="aimg">
            <div class="aimg-msg">
              <div>步入阶梯式</div>
              <div>的成长道路</div>
            </div>
          </div>
          <div class="aimg">
            <img v-lazy="require('@/assets/interHospital/2.png')" src="" alt="">
            
          </div>
          <div class="aimg">
            <div class="aimg-msg">
              <div>掌握科学</div>
              <div>的学习方法</div>
            </div>
          </div>
          <div class="aimg">
            <img v-lazy="require('@/assets/interHospital/3.png')" src="" alt="">
            
          </div>
        </div>
        <div class="imgs_box-flex">
          <div class="aimg">
            <div class="aimg-msg">
              <div>打好扎实的</div>
              <div>心理学基础</div>
            </div>
          </div>
          <div class="aimg">
            <img v-lazy="require('@/assets/interHospital/4.png')" src="" alt="">
            
          </div><div class="aimg">
            <div class="aimg-msg">
              <div>享受实操</div>
              <div>的舞台</div>
            </div>
          </div>
          <div class="aimg">
            <img v-lazy="require('@/assets/interHospital/5.png')" src="" alt="">
          </div>
          <div class="aimg">
            <div class="aimg-msg">
              <div>获得广阔</div>
              <div>的人脉</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--申请试用-->
    <div class="test" ref="test">
      <div class="title-box reveal">
        <div class="title1">申请免费试用</div>
        <div class="title2">联系客服，开通账号体验</div>
      </div>
      <div class="test-img">
        <img
          v-lazy="require('@/assets/org/test.jpg')"
          style="height:600px;width:1200px"
          class="revealImg"
          @click="gotoLogin"
        />
      </div>
    </div>
    <!-- <div style="font-size:35px; width:950px; height:50px; text-align:center; font-weight:bold;" >浙ICP备2022026803号</div> -->

  </div>
</template>

<script>
import scrollReveal from "scrollreveal";
export default {
  name: "org",
  data() {
    return {
      tabs: [
        {
          name: "专业课程",
          id: "intro",
          hover: false,
        },
        {
          name: "成长之路",
          id: "scene",
          hover: false,
        },
        {
          name: "平台福利",
          id: "tool",
          hover: false,
        },
        {
          name: "申请试用",
          id: "test",
          hover: false,
        },
      ],
      showTabHeader: false,
      scrollReveal: new scrollReveal(),
    };
  },
  mounted() {
    this.getReveal();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll: function() {
      this.$nextTick(() => {
        let top = this.$refs.tabs.getBoundingClientRect().top;
        this.showTabHeader = top <= 0 ? true : false;
        if (top <= 0) {
          this.tabs.forEach((tab) => {
            let tabData = this.$refs[tab.id].getBoundingClientRect();
            if (
              Math.floor(tabData.top) <= 0 &&
              Math.floor(tabData.height + tabData.top) > 0
            ) {
              tab["hover"] = true;
            } else {
              tab["hover"] = false;
            }
          });
        }
      });
    },
    scrollTo: function(name) {
      this.$nextTick(() => {
        this.$refs[name].scrollIntoView({ block: "start", behavior: "smooth" });
      });
    },
    gotoLogin() {
      this.$router.push("/login");
    },
    getReveal() {
      let animation = {
        duration: 1000,
        reset: false,
        mobile: false,
        opacity: 0,
        easing: "ease-in-out",
        enter: "bottom",
        distance: "40px",
      };
      let setReveal = (className, delay) => {
        let revealAnimation = JSON.parse(JSON.stringify(animation));
        revealAnimation["delay"] = delay;
        this.scrollReveal.reveal(className, revealAnimation);
      };
      setReveal(".reveal", 0);
      setReveal(".revealImg", 500);
      setReveal(".revealContent", 800);
      setReveal(".reveal1", 200);
      setReveal(".reveal2", 500);
      setReveal(".reveal3", 800);
      setReveal(".reveal4", 1100);
    },
  },
};
</script>
<style scoped lang="scss">
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.org {
  width: 100%;
  background: #fff;

  .title-box {
    @include flex($direction: column, $justify: center);
    text-align: center;
    color: $title-color;
    .title1 {
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .title2 {
      font-size: 24px;
      line-height: 33px;
    }
  }

  .banner {
    @include flex($direction: column);
    width: 100%;
    height: 500px;
    overflow: hidden;
    background-image: url("~@/assets/interHospital/Frame 4251.jpg");
    background-size: 100% 500px;
    background-repeat: no-repeat;
    background-color: #fff;

    .banner-img {
      width: 100%;
      height: 500px;
    }
  }

  .tabs {
    width: 100%;
    height: 60px;
    background: #f0f1f7;
    @include flex($justify: center);
    .contain {
      width: $width;
      height: 100%;
      @include flex($justify: space-around);
      .tab {
        font-size: 16px;
        color: #414246;
      }
    }
  }

  .fixed {
    background-color: #f5f6f8;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    height: 60px;
    .tab {
      position: relative;
      padding: 20px 0;
    }
    .tab::after {
      position: absolute;
      content: "";
      bottom: 1px;
      left: 50%;
      width: 0;
      height: 3px;
      border-radius: 30px;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background-color: $color;
      -webkit-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }
    .tab.hover {
      color: $color;
    }
    .tab:hover {
      color: $color;
    }
    .tab:hover::after {
      width: 100%;
    }
  }

  .intro {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    .title-box {
      margin-bottom: 60px;
    }
    .intro-contain {
      width: $width;
      @include flex($justify: space-between);
      .box {
        width: 390px;
        // height: 430px;
        border-radius: 4px;
        overflow: hidden;
        background: #fafbff;
        .box-img {
          width: 390px;
          height: 270px;
        }
        .box-main {
          padding: 30px 40px;
          box-sizing: border-box;
          height: 220px;
          .logo1 {
            width: 52px;
            height: 46px;
            margin-right: 24px;
            flex-shrink: 0;
          }
          .logo2 {
            width: 57px;
            height: 44px;
            margin-right: 20px;
            flex-shrink: 0;
          }
          .logo3 {
            width: 52px;
            height: 41px;
            margin-right: 24px;
            flex-shrink: 0;
          }
          .flex {
            @include flex;
          }
          .box-title {
            color: $title-color;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            flex: 1;
            text-align: center;
          }
          .box-intro {
            color: $intro-color;
            font-size: 16px;
            line-height: 24px;
            margin-top: 20px;
            text-align: center;
            .btn{
              width: 150px;
              height: 40px;
              background: #536CFE;
              border-radius: 20px 20px 20px 20px;
              opacity: 1;
              color: #ffffff;
            }
          }
        }
      }
      .box:hover {
        box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
      }
    }
  }

  .scene {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    .title-box {
      margin-bottom: 60px;
    }
    .scene-contain {
      width: 100%;
      @include flex($justify: space-between);
      flex-wrap: wrap;
      .bg-img{
        width: 100%;
        height: 850px;
        background: url('../../assets/interHospital/Group 494.png') no-repeat;
        background-size: 100%;
        .card1{
          display: flex;
          justify-content: center;
          // margin: 10px;
          .card-one{
            width: 580px;
            height: 400px;
            background: #FAFBFF;
            border-radius: 10px 10px 10px 10px;
            opacity: 1;
            margin: 10px 10px;
            position: relative;
            .card-img{
              position: absolute;
              bottom: 30px;
              right: 30px;
              width: 100px;
              height: 100px;
              img{
                width: 100%;
              }
            }
            .card-img2{
              position: absolute;
              bottom: 30px;
              left: 30px;
              width: 100px;
              height: 100px;
              img{
                width: 100%;
              }
            }
            .card-img3{
              position: absolute;
              top: 30px;
              right: 30px;
              width: 100px;
              height: 100px;
              img{
                width: 100%;
              }
            }
            .card-img4{
              position: absolute;
              top: 30px;
              left: 30px;
              width: 100px;
              height: 100px;
              img{
                width: 100%;
              }
            }
            .msg-yellow{
                display: block;
                margin-top: 30px;
                margin-left: 40px;
                .yellow-p{
                  width: 373px;
                  height: 24px;
                  font-size: 18px;
                  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                  font-weight: 400;
                  color: #F4BB01;
                  line-height: 21px;
                  margin: 0;
                }
              }
            .small{
              width: 100%;
              height: 30px;
              line-height: 34px;
              margin-top: 20px;
              display: flex;
              margin-left: 40px;
              .small-box{
                height: 30px;
                line-height: 30px;
                display: flex;
                align-items: center;
                margin-right: 10px;
                .small-dian{
                  width: 8px;
                  height: 8px;
                  background: #536CFE;
                  opacity: 1;
                  display: block;
                  margin-right: 5px;
                }
              }
              
            }
            
            .title{
              width: 140px;
              height: 37px;
              font-size: 28px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
              line-height: 33px;
              margin: 0;
              margin-top: 37px;
              margin-left: 45px;
              margin-bottom: 22px;
            }
            .msg{
              height: 37px;
              font-size: 28px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
              line-height: 33px;
              margin: 0;
              margin-left: 40px;
              margin-top: 10px;
            }
            .h1{
              width: 150px;
              height: 2px;
              background: #536CFE;
              border-radius: 0px 0px 0px 0px;
              opacity: 1;
              margin: 14px 0 0 42px;
            }
          }
        }
      }
      .box {
        @include flex($justify: space-between);
        width: $width;
        height: 350px;
        border-radius: 4px;
        overflow: hidden;
        background: #fafbff;
        margin-bottom: 20px;
        padding: 40px;
        .box-img {
          width: 480px;
          height: 270px;
        }
        .box-main {
          .flex {
            @include flex();
          }
          .box-title {
            color: $title-color;
            font-size: 24px;
            font-weight: 500;
            line-height: 33px;
            margin-left: 20px;
          }
          .box-logo1 {
            width: 45px;
            height: 50px;
            margin-right: 20rpx;
            flex-shrink: 0;
          }
          .box-logo2 {
            width: 51px;
            height: 49px;
            margin-right: 20rpx;
            flex-shrink: 0;
          }
          .box-logo3 {
            width: 45px;
            height: 44px;
            margin-right: 20rpx;
            flex-shrink: 0;
          }
          .box-intro {
            color: $intro-color;
            font-size: 16px;
            line-height: 30px;
            margin-top: 30px;
          }
        }
      }
      .box:hover {
        box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
      }
    }
  }

  .tool {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    background: #fff;
    
    .imgs-box{
      width: 1200px;
      height: 480px;
      .imgs_box-flex{
        display: flex;
        .aimg{
          width: 240px;
          height: 240px;
          overflow: hidden;
          background: #FAFBFF;
          display: flex;
          justify-content: center;
          align-items: center;
          .aimg-msg{
            div{
              width: 120px;
              height: 32px;
              font-size: 24px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #000000;
              line-height: 28px;
            }
          }
          img{
            width: 100%;
            float: left;
          }
        }
      }
    }
  }

  .report {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 60px;
    }
    .report-contain {
      width: $width;
      @include flex($justify: space-between);
      .box {
        @include flex($direction: column);
        width: 288px;
        height: 448px;
        border-radius: 4px;
        background: #fafbff;
        padding: 20px 24px;
        .box-title {
          @include flex($direction: column, $justify: center);
          width: 100%;
          height: 156px !important;
          font-size: 24px;
          color: #596df8;
          font-weight: 500;
          background-color: #fafbff;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 76px 156px !important;
        }
        .title-bg1 {
          background-image: url("~@/assets/org/title-bg1.png");
        }
        .title-bg2 {
          background-image: url("~@/assets/org/title-bg2.png");
        }
        .title-bg3 {
          background-image: url("~@/assets/org/title-bg3.png");
        }
        .box-intro {
          font-size: 16px;
          color: $intro-color;
          line-height: 24px;
        }
        .box-img1 {
          width: 178px;
          height: 178px;
        }
        .box-img2 {
          width: 151px;
          height: 151px;
          margin-top: 20px;
        }
        .box-img3 {
          width: 161px;
          height: 161px;
          margin-top: -10px;
        }
        .box-img4 {
          width: 212px;
          height: 213px;
        }
      }
      .box:hover {
        box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
      }
    }
  }

  .test {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;

    .title-box {
      margin-bottom: 40px;
    }
    .test-img {
      width: $width;
      height: 600px;
    }
  }
}
</style>
